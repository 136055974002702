/**
 * @Date: 2022-11-14 12:03:02
 * @LastEditors: wangzhicai 1145717593@qq.com
 * @LastEditTime: 2022-12-12 15:56:47
 * @FilePath: /udefined/resources/js/app.js
 */
/*
 * @Date: 2022-10-31 15:58:22
 */

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

function gtag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

// 注册全局路由守卫
router.beforeEach((to, from, next) => {
  // console.log('process.env.NODE_ENV = ' + process.env.NODE_ENV)
  // eslint-disable-next-line eqeqeq
  if (process.env.NODE_ENV == 'production') {
    // console.log(to.fullPath)
    let gtagValue = 'yichang'
    if ((to.fullPath.startsWith('/home'))) {
      console.log("-----atHome------")
      gtagValue = 'atHome'
      gtag('event', gtagValue)
    } else if (to.fullPath.startsWith('/product/detail')) {
      const searchParams = new URLSearchParams(to.fullPath.split('?')[1])
      gtagValue = 'detail' + searchParams.get('productId')
      gtag('event', gtagValue)
    } else if (to.fullPath.startsWith('/editor')) {
      console.log("-----editor------")
      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
      
      var productId = getParameterByName('product_id');
      gtagValue = 'custom' + productId
      console.log('gtagValue ='+gtagValue)
      gtag('event', gtagValue)
    } else if (to.fullPath.startsWith('/shoppingcart/bill')) {
      const searchParams = new URLSearchParams(to.fullPath.split('?')[1])
      gtagValue = 'bill' + searchParams.get('productId')
      gtag('event', gtagValue)
    } else if (to.fullPath.startsWith('/order/pay/result')) {
      const searchParams = new URLSearchParams(to.fullPath.split('?')[1])
      gtagValue = 'finish' + searchParams.get('productIds[0]')
      gtag('event', gtagValue)
    }
    // console.log(gtagValue)
  }
  // 在路由进入前显示loading组件
  NProgress.start()
  next()
})
router.afterEach(() => {
  // 在路由进入后隐藏loading组件
  NProgress.done()
})

import { init } from './api/home.js'

import VConsole from 'vconsole'

// eslint-disable-next-line eqeqeq
if (process.env.NODE_ENV == 'dev') {
  var vConsole = new VConsole()
  vConsole.destroy
}

init().then(res => {
  // eslint-disable-next-line eqeqeq
  if (res.data.code == 0) {
    new Vue({
      el: '#app',
      router,
      // VueAnalytics,
      render: function(h) {
        return h(App)
      }
    })
  }
})
