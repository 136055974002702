import axios from 'axios'
// import cookies from 'vue-cookies'
import Cookies from 'js-cookie'

import Vue from 'vue'
import LoadingComponent from '@/views/common/LoadingComponent.vue'

import { Toast
} from 'vant'
Vue.use(Toast)

axios.defaults.withCredentials = true

let baseUrl = ''
// eslint-disable-next-line eqeqeq
if (process.env.NODE_ENV == 'local') {
  baseUrl = '/api'
} else {
  baseUrl = '/api'
}

const service = axios.create({
  baseURL: baseUrl,
  timeout: 60000
})

service.defaults.withCredentials = true

let loadingInstance = null // 定义loading实例

service.interceptors.request.use(

  (config) => {
    const loadingId = Math.random().toString(36).substring(2)
    const Loading = Vue.extend(LoadingComponent)
    loadingInstance = new Loading({
      el: document.createElement('div'),
      mounted() {
        this.$el.setAttribute('id', `${loadingId}`)
      }
    })
    document.body.appendChild(loadingInstance.$el)

    // 将loadingId添加到config中，以便在response拦截器中使用
    config.headers.loadingId = loadingId

    const hrefUrl = location.href
    const channelSlog = decodeURIComponent(((new RegExp('[?|&]' + 'ud_channel' + '=' + '([^&;]+?)(&|#|;|$)').exec(hrefUrl)) || ['', ''])[1].replace(/\+/g, '%20')) || null
    // let channelSlog = decodeURIComponent((new RegExp('[?|&]' + 'channel' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    if (channelSlog) {
      // cookies.set('ud_channel', channelSlog)
      // eslint-disable-next-line eqeqeq
      if (process.env.NODE_ENV == 'production') {
        Cookies.set('ud_channel', channelSlog, { domain: '.udefine.shop', expires: 36500 })
      } else {
        Cookies.set('ud_channel', channelSlog, { domain: '.caiseshi.cn', expires: 36500 })
      }
    }
    // eslint-disable-next-line eqeqeq
    if (process.env.NODE_ENV == 'local') {
      config.headers['local-host'] = 'localhost'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 全局响应拦截
service.interceptors.response.use((response) => {
  const { headers } = response.config
  const loadingId = headers.loadingId
  const loading = document.getElementById(`${loadingId}`)
  loading.parentNode.removeChild(loading)

  // eslint-disable-next-line eqeqeq
  if (response.data.code == 400) {
    Toast.setDefaultOptions({ duration: 2000 })
    Toast({
      message: response.data.error
    })
    return Promise.reject()
  } else if
  (response.data.code !== 0 && response.data.code !== 200) {
    // Toast.loading({
    //   message: response.data.error, // ?? 'please waiting',
    //   forbidClick: true,
    //   loadingType: 'spinner'
    // })
    return Promise.reject()
  }
  return response
},
(error) => {
  // if (error.response && error.response.status === 401) {
  //   window.location.href = '#/login'
  // }
  return Promise.reject(error)
}
)

export const post = (url, data) => service.post(url, data)

export default service
