/**
 * @Date: 2022-11-14 12:03:02
 * @LastEditors: wangzhicai 1145717593@qq.com
 * @LastEditTime: 2022-12-01 22:08:39
 * @FilePath: /udefined/resources/js/router/web.js
 */
/*
 * @Date: 2022-11-13 22:06:24
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Home = () => import('../views/home/HomePage.vue')
// 我的
const MyInfo = () => import('../views/myInfo/MyInfo.vue')

// information
const AboutUs = () => import('../views/common/information/AboutUs.vue')
const Policy = () => import('../views/common/information/Policy.vue')
const Return = () => import('../views/common/information/Return.vue')
const Rights = () => import('../views/common/information/Rights.vue')
const ShoppingPolicy = () => import('../views/common/information/ShoppingPolicy.vue')
const Terms = () => import('../views/common/information/Terms.vue')

// 商品详情
const Detail = () => import('../views/product/detail/Detail.vue')

// 评论
// const Feedback = () => import('../views/product/feedback/Feedback.vue')
// 制作照片书
const Photobook = () => import('../views/product/cus/Photobook.vue')
const EvenPhotos = () => import('../views/product/cus/EvenPhotos.vue')
// const Mug = () => import('../views/product/cus/EnamelMug.vue');
const CommonCustom = () => import('../views/product/cus/CommonCustom.vue')
const FreeCustom = () => import('../views/product/cus/FreeCustom.vue')

// // // 购物车
const ShoppingCartBill = () => import('../views/shoppingcart/Bill.vue')
// const ShoppingList = () => import('../views/shoppingcart/List.vue');

// // 地址
const UserAddressList = () => import('../views/userAddress/AddressList.vue')

// // 订单列表
const OrderList = () => import('../views/order/orderList/OrderList.vue')

// // 订单详情
const OrderDetail = () => import('../views/order/orderDetail/OrderDetail.vue')

// // 预览
const PreviewCommonCustom = () => import('../views/order/preview/PreviewCommonCustom.vue')
const PreviewPhotoBook = () => import('../views/order/preview/PreviewPhotoBook.vue')
const PreviewEvenPhotos = () => import('../views/order/preview/PreviewEvenPhotos.vue')
const PreviewFreeCustom = () => import('../views/order/preview/PreviewFreeCustom.vue')

// 支付结果
const PayResult = () => import('../views/order/payResult/PayResult.vue')
const PayResultChecked = () => import('../views/order/payResult/PayResultChecked.vue')

// const Hammer = () => import('../web/views/test/Hammer.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: { render: (e) => e('router-view') },
    redirect: '/home',
    children: [
      { path: 'home', name: 'home', alias: '/home', component: Home, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { keepAlive: true }
  },
  {
    path: '/',
    name: 'myInfo',
    component: { render: (e) => e('router-view') },
    children: [
      { path: 'myInfo', name: 'myInfo', alias: '/myInfo', component: MyInfo, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/information',
    name: 'information',
    component: { render: (e) => e('router-view') },
    children: [
      { path: 'aboutUs', name: 'information', component: AboutUs, meta: { keepAlive: true }},
      { path: 'policy', name: 'policy', component: Policy, meta: { keepAlive: true }},
      { path: 'return', name: 'return', component: Return, meta: { keepAlive: true }},
      { path: 'rights', name: 'rights', component: Rights, meta: { keepAlive: true }},
      { path: 'shoppingPolicy', name: 'shoppingPolicy', component: ShoppingPolicy, meta: { keepAlive: true }},
      { path: 'terms', name: 'terms', component: Terms, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/editor',
    name: 'product',
    component: { render: (e) => e('router-view') },
    redirect: '/editor/detail',
    children: [
      { path: 'detail', name: 'detail', component: Detail, meta: { keepAlive: true }},
      // { path: 'feedback', name: 'feedback', component: Feedback, meta: { keepAlive: true }},
      { path: 'photo_book', name: 'photobook', component: Photobook, meta: { keepAlive: true }},
      { path: 'even_photos', name: 'evenPhotos', component: EvenPhotos, meta: { keepAlive: true }},
      { path: 'custom', name: 'custom', component: CommonCustom, meta: { keepAlive: true }},
      { path: 'free_custom', name: 'freeCustom', component: FreeCustom, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: { render: (e) => e('router-view') },
    children: [
      { path: 'list', name: 'orderList', component: OrderList, meta: { keepAlive: true }},
      { path: 'detail', name: 'orderDetail', component: OrderDetail, meta: { keepAlive: true }},
      { path: 'preview/commonCustom', name: 'previewCommonCustom', component: PreviewCommonCustom, meta: { keepAlive: true }},
      { path: 'preview/photoBook', name: 'previewPhotoBook', component: PreviewPhotoBook, meta: { keepAlive: true }},
      { path: 'preview/even_photos', name: 'previewEvenPhotos', component: PreviewEvenPhotos, meta: { keepAlive: true }},
      { path: 'preview/freeCustom', name: 'previewFreeCustom', component: PreviewFreeCustom, meta: { keepAlive: true }},
      { path: 'pay/result', name: 'payResult', component: PayResult, meta: { keepAlive: true }},
      { path: 'pay/result/checked', name: 'payResultChecked', component: PayResultChecked, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/user/address',
    name: 'userAddress',
    component: { render: (e) => e('router-view') },
    children: [
      { path: 'list', name: 'userAddressList', component: UserAddressList, meta: { keepAlive: true }}
    ]
  },
  {
    path: '/shoppingcart',
    name: 'shoppingcart',
    component: { render: (e) => e('router-view') },
    // redirect: '/shoppingcart/list',
    children: [
      // {path: 'list', name: 'shoppingcartList', component: ShoppingList, meta: {keepAlive: true}},
      { path: 'bill', name: 'shoppingcartBill', component: ShoppingCartBill, meta: { keepAlive: true }}
    ]
  },
  {
    path: '*',
    name: 'home1',
    component: { render: (e) => e('router-view') },
    redirect: '/home',
    children: [
      { path: 'home', name: 'home', component: Home, meta: { keepAlive: true }}
    ]
  }
]

const router = new Router({
  mode: 'history',
  routes
})
// export default WebRouters
export default router
