import request from '../util/request'

export function init() {
  return request({
    url: '/common/init',
    method: 'get'
  })
}

export function homeDate() {
  return request({
    url: '/common/home',
    method: 'get'
  })
}
