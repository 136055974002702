export default {
  mounted() {
    if (!this.isMobile()) {
      this.simulateWidth();
    }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    simulateWidth() {
      const width = Math.min(400, window.innerWidth);
      document.body.style.width = `${width}px`;
      document.body.style.margin = '0 auto';
    }
  }
};